<template>
<div class="wrapper" v-loading.fullscreen.lock="loading" :element-loading-text="$t('connecting')" element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.8)">
    <div class="top-header">
        <div class="flex items-center gap-3">
            <button class="bg-white p-1 w-8 h-8 rounded-xl" @click="$router.go(-1)">
                <i class="el-icon-arrow-left"></i>
            </button>
            <h1>{{ $route.params.id ? res1.name_cyr : $t('add_new_hydroelectricpowerstations') }}</h1>
        </div>
    </div>
    <div class="wp-body"> 
        <div class="uform">
            <el-form :model="res1" :rules="rules1" ref="res1" class="Form">
                <div class="input-section">
                    <el-row :gutter="30">
                        <el-col :md="8">
                            <label class="item">
                                <p>{{$t('hydroelectricpowerstations_name')}}</p>
                                <el-form-item prop="name_cyr">
                                    <el-input name="name_cyr" :disabled="disabled1" v-model="res1.name_cyr" :placeholder="$t('hydroelectricpowerstations_name')" clearable />
                                </el-form-item>
                            </label>

                            <label class="item">
                                <p>{{$t('hydroelectricpowerstations_organization_name')}}</p>
                                <el-form-item prop="organization_name">
                                    <el-input name="organization_name" :disabled="disabled1" v-model="res1.organization_name" :placeholder="$t('hydroelectricpowerstations_organization_name')" clearable />
                                </el-form-item>
                            </label>
                            <label class="item">
                                <p>{{$t('hydroelectricpowerstations_region')}}</p>
                                <el-form-item prop="region">
                                    <el-select name="region" :disabled="disabled1" v-model="res1.region" :loading="loading" class="w-full" :placeholder="$t('hydroelectricpowerstations_region')">
                                        <el-option v-for="item in dictionary.region" :key="item.id" :label="item[`name_${$i18n.locale}`]? item[`name_${$i18n.locale}`]:item.name_cyr" :value="item.id">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </label>
                            <label class="item">
                                <p>{{$t('hydroelectricpowerstations_district')}}</p>
                                <el-form-item prop="district">
                                    <el-select name="district" :disabled="disabled1" v-model="res1.district" class="w-full" clearable :placeholder="$t('hydroelectricpowerstations_district')">
                                        <el-option v-for="item in current_district" :key="item.id" :label="item[`name_${$i18n.locale}`]? item[`name_${$i18n.locale}`]:item.name_cyr" :value="item.id">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </label>
                            <label class="item">
                                <p>{{$t('hydroelectricpowerstations_address')}}</p>
                                <el-form-item prop="address">
                                    <el-input name="address" :disabled="disabled1" v-model="res1.address" :placeholder="$t('hydroelectricpowerstations_address')" clearable />
                                </el-form-item>
                            </label>
                            <label class="item">
                                <p>{{$t('hydroelectricpowerstations_year_of_construction')}}</p>
                                <el-form-item prop="area_watersource">
                                    <el-date-picker name="area_watersource" :disabled="disabled1" style="width: 100%" v-model="res1.year_of_construction" type="year" value-format="yyyy" :placeholder="$t('hydroelectricpowerstations_year_of_construction')">
                                    </el-date-picker>
                                </el-form-item>
                            </label>
                            <label class="item">
                                <p>{{$t('hydroelectricpowerstations_date_of_accept')}}</p>
                                <el-form-item>
                                    <el-date-picker name="hydroelectricpowerstations_date_of_accept" :disabled="disabled1" style="width: 100%" v-model="res1.date_of_accept" value-format="dd.MM.yyyy" format="dd.MM.yyyy" :placeholder="$t('hydroelectricpowerstations_date_of_accept')">
                                    </el-date-picker>
                                </el-form-item>
                            </label>
                        </el-col>
                        <el-col :md="8">
                            <label class="item">
                                <p> {{$t('water_base_buildingseismicity')}} </p>
                                <el-form-item prop="buildingseismicity">
                                    <el-select name="buildingseismicity" :disabled="disabled1" class="w-full" v-model="res1.buildingseismicity" clearable  :placeholder="$t('finalconclusion')" >
                                        <el-option v-for="item in dictionary.buildingseismicity" :key="item.id" :label="item[`name_${$i18n.locale}`]? item[`name_${$i18n.locale}`]:item.name_cyr" :value="item.id">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </label>
                            <label class="item">
                                <p>{{$t('hydroelectricpowerstations_reservoir_class')}} </p>
                                <el-form-item prop="reservoir_class">
                                    <el-select name="reservoir_class" :disabled="disabled1" v-model="res1.reservoir_class" class="w-full" clearable :placeholder="$t('hydroelectricpowerstations_reservoir_class')">
                                        <el-option v-for="item in dictionary.reservoirclass" :key="item.id" :label="item[`name_${$i18n.locale}`]? item[`name_${$i18n.locale}`]:item.name_cyr" :value="item.id">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </label>
                            <label class="item">
                                <p> {{$t('water_base_earthquake_tolerance')}} </p>
                                <el-form-item prop="earthquake_tolerance">
                                    <el-input name="earthquake_tolerance" :disabled="disabled1" v-model="res1.earthquake_tolerance" :placeholder="$t('water_base_earthquake_tolerance')" clearable />
                                </el-form-item>
                            </label>
                            <label class="item">
                                <p>{{$t('hydroelectric_power_stations_type')}} </p>
                                <el-form-item prop="hydroelectric_power_stations_type">
                                    <el-select name="hydroelectric_power_stations_type" :disabled="disabled1" v-model="res1.hydroelectric_power_stations_type" class="w-full" clearable :placeholder="$t('hydroelectric_power_stations_type')">
                                        <el-option v-for="item in dictionary.reservoirtype" :key="item.id" :label="item[`name_${$i18n.locale}`]? item[`name_${$i18n.locale}`]:item.name_cyr" :value="item.id">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </label>
                            <label class="item">
                                <p>{{$t('earthquake_tolerance_qmq')}}</p>
                                <el-form-item prop="earthquake_tolerance_qmq">
                                    <el-input name="earthquake_tolerance_qmq" :disabled="disabled1" v-model="res1.earthquake_tolerance_qmq" :placeholder="$t('earthquake_tolerance_qmq')" clearable />
                                </el-form-item>
                            </label>
                            <label class="item">
                                <p> {{$t('finalconclusion')}} </p>
                                <el-form-item prop="finalconclusion">
                                    <el-select name="finalconclusion" :disabled="disabled1" v-model="res1.finalconclusion" class="w-full" clearable  :placeholder="$t('finalconclusion')" >
                                        <el-option v-for="item in dictionary.finalconclusion" :key="item.id" :label="item[`name_${$i18n.locale}`]? item[`name_${$i18n.locale}`]:item.name_cyr" :value="item.id">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </label>

                            <!-- Сув омборининг лойиҳа бўйича асосий кўрсаткичлари-->
                            <p class="indicator ">{{$t('hydroelectricpowerstations_main_project')}}:</p>
                            <label class="item">
                                <p>{{$t('max_water_pressure_height')}}</p>
                                <div class="flex">
                                    <el-form-item class="flex-auto w-full" prop="max_water_pressure_height.value">
                                        <el-input name="max_water_pressure_height" type="number" :disabled="disabled1" :placeholder="$t('max_water_pressure_height')" v-model="res1.max_water_pressure_height.value" clearable />
                                    </el-form-item>
                                    <el-form-item prop="max_water_pressure_height.unit">
                                        <el-select name="max_water_pressure_height_unit" :disabled="disabled1" v-model="res1.max_water_pressure_height.unit" class="ml-3" clearable>
                                            <el-option v-for="item in units.unit_1" :key="item.id" :label="item[`name_${$i18n.locale}`]? item[`name_${$i18n.locale}`]:item.name_cyr" :value="item.id">
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                </div>
                            </label>

                        </el-col>
                        <el-col :md="8">
                            <!-- ---------------------- -->
                            <p class="indicator ">{{$t('hydroelectricpowerstations_gydrogen')}}:</p>
                            <label class="item">
                                <p>{{$t('brand')}}</p>
                                <el-form-item prop="hydraulic_unit_brand">
                                    <el-input name="hydraulic_unit_brand" :disabled="disabled1" v-model="res1.hydraulic_unit_brand" :placeholder="$t('brand')" clearable />
                                </el-form-item>
                            </label>
                            <label class="item">
                                <p> {{$t('count')}} </p>
                                <el-form-item prop="hydraulic_unit_count">
                                    <el-input name="hydraulic_unit_count" :disabled="disabled1" v-model="res1.hydraulic_unit_count" :placeholder="$t('count')" clearable />
                                </el-form-item>
                            </label>
                            <label class="item">
                                <p>{{$t('water_spend')}}</p>
                                <div class="flex"> 
                                    <el-form-item class="flex-auto w-full" prop="hydraulic_unit_water_consumption.value">
                                        <el-input name="hydraulic_unit_count" type="number" :disabled="disabled1" v-model="res1.hydraulic_unit_water_consumption.value" :placeholder="$t('water_spend')" clearable />
                                    </el-form-item>
                                    <el-form-item prop="hydraulic_unit_water_consumption.unit">
                                        <el-select name="hydraulic_unit_water_consumption_unit" :disabled="disabled1" v-model="res1.hydraulic_unit_water_consumption.unit" class="ml-3" clearable>
                                            <el-option v-for="item in units.unit_2" :key="item.id" :label="item[`name_${$i18n.locale}`]? item[`name_${$i18n.locale}`]:item.name_cyr" :value="item.id">
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                </div>
                            </label>
                            <label class="item">
                                <p>{{$t('power')}}, млн кВт. соат</p> 
                                <div class="flex">
                                    <el-form-item class="flex-auto w-full" prop="hydraulic_unit_power.value">
                                        <el-input name="hydraulic_unit_power" type="number" :disabled="disabled1" v-model="res1.hydraulic_unit_power.value" :placeholder="$t('power')" clearable />
                                    </el-form-item>
                                    <el-form-item prop="hydraulic_unit_power.unit">
                                        <el-select name="hydraulic_unit_power_unit" :disabled="disabled1" v-model="res1.hydraulic_unit_power.unit" class="ml-3" clearable>
                                            <el-option v-for="item in units.unit_4" :key="item.id" :label="item[`name_${$i18n.locale}`]? item[`name_${$i18n.locale}`]:item.name_cyr" :value="item.id">
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                </div>
                            </label>
                            <!-- ------------------------------------------ -->
                            <p class="indicator ">{{$t('hydroelectricpowerstations_main')}}:</p>
                            <label class="item"> 
                                <p>{{$t('water_spend')}}, куб м/с</p>
                                <div class="flex">
                                    <el-form-item class="flex-auto w-full" prop="general_water_consumption.value">
                                        <el-input name="general_water_consumption" type="number" :disabled="disabled1" v-model="res1.general_water_consumption.value" :placeholder="$t('water_spend')" clearable />
                                    </el-form-item>
                                    <el-form-item prop="general_water_consumption.unit">
                                        <el-select name="general_water_consumption_unit" :disabled="disabled1" v-model="res1.general_water_consumption.unit" class="ml-3" clearable>
                                            <el-option v-for="item in units.unit_2" :key="item.id" :label="item[`name_${$i18n.locale}`]? item[`name_${$i18n.locale}`]:item.name_cyr" :value="item.id">
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                </div>
                            </label>
                            <label class="item">
                                <p>{{$t('power')}}, млн кВт. соат</p>
                                <div class="flex">
                                    <el-form-item class="flex-auto w-full" prop="general_power.value">
                                        <el-input name="general_power" type="number" :disabled="disabled1" v-model="res1.general_power.value" :placeholder="$t('power')" clearable />
                                    </el-form-item>
                                    <el-form-item prop="general_power.unit">
                                        <el-select name="general_power_unit" :disabled="disabled1" v-model="res1.general_power.unit" class="ml-3" clearable>
                                            <el-option v-for="item in units.unit_4" :key="item.id" :label="item[`name_${$i18n.locale}`]? item[`name_${$i18n.locale}`]:item.name_cyr" :value="item.id">
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                </div>
                            </label>
                            <!-- ---------------------------------- -->
                            <p class="indicator ">{{$t('pressure_pipe')}}: </p>
                            <label class="item">
                                <p> {{$t('count')}} </p>
                                <el-form-item prop="pressure_pipe_count">
                                    <el-input name="pressure_pipe_count" :disabled="disabled1" type="number" v-model="res1.pressure_pipe_count" :placeholder="$t('count')" clearable />
                                </el-form-item>
                            </label>
                            <label class="item">
                                <p>{{$t('diametr')}}, м</p>
                                <div class="flex">
                                    <el-form-item class="flex-auto w-full" prop="pressure_pipe_diameter.value">
                                        <el-input name="pressure_pipe_diameter" type="number" :disabled="disabled1" v-model="res1.pressure_pipe_diameter.value" :placeholder="$t('diametr')" clearable />
                                    </el-form-item>
                                    <el-form-item prop="pressure_pipe_diameter.unit">
                                        <el-select name="pressure_pipe_diameter_unit" :disabled="disabled1" v-model="res1.pressure_pipe_diameter.unit" class="ml-3" clearable>
                                            <el-option v-for="item in units.unit_1" :key="item.id" :label="item[`name_${$i18n.locale}`]? item[`name_${$i18n.locale}`]:item.name_cyr" :value="item.id">
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                </div>
                            </label>
                            <label class="item">
                                <p>{{$t('lengths')}}, м</p>
                                <div class="flex">
                                    <el-form-item class="flex-auto w-full" prop="pressure_pipe_length.value">
                                        <el-input name="pressure_pipe_length" type="number" :disabled="disabled1" v-model="res1.pressure_pipe_length.value" :placeholder="$t('lengths')" clearable />
                                    </el-form-item>
                                    <el-form-item prop="pressure_pipe_length.unit">
                                        <el-select name="pressure_pipe_length_unit" :disabled="disabled1" v-model="res1.pressure_pipe_length.unit" class="ml-3" clearable>
                                            <el-option v-for="item in units.unit_1" :key="item.id" :label="item[`name_${$i18n.locale}`]? item[`name_${$i18n.locale}`]:item.name_cyr" :value="item.id">
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                </div>
                            </label>
                            <!-- ------------------------------------------------- -->

                            <div class="actions mt-10">
                                <div class=" flex justify-end w-full" v-if="role !== 'admin' &&  !disabled1">
                                    <!-- <el-button v-if="res1.id" class="primary-btn"><i class="el-icon-edit mr-1"></i>Ўзгартириш</el-button> -->
                                    <div>
                                        <el-button @click.native="save1()" id="save_button" class="primary-btn " style="width:150px"><i class="el-icon-document-checked mr-1"></i>{{$t('save')}}</el-button>
                                    </div>

                                </div>
                            </div>
                        </el-col>
                    </el-row>
                </div>
            </el-form>
        </div>
    </div>
</div>
</template>

<script>
export default {
    data() {
        return {
            disabled1: false,
            disabled2: false,
            isCreated2: false,
            loading: false,
            activeNames: "1",
            current_district: {},
            obj: {},
            isCreated: false,
            res1: {
                name_cyr: '',
                address: '',
                year_of_construction: '',
                date_of_accept: null,
                organization_name: '',
                user_organization_name: '',
                earthquake_tolerance: '',
                region: '',
                district: "",
                reservoir_class: '',
                finalconclusion: '',
                hydraulic_unit_brand: '',
                hydraulic_unit_count: '',
                pressure_pipe_count: '',
                earthquake_tolerance_qmq: '',
                hydroelectric_power_stations_type: '',
                buildingseismicity:'',
                max_water_pressure_height: {
                    value: null,
                    unit: 1
                },
                hydraulic_unit_water_consumption: {
                    value: null,
                    unit: 13
                },
                hydraulic_unit_power: {
                    value: null,
                    unit: 11
                },
                general_water_consumption: {
                    value: null,
                    unit: 13
                },
                general_power: {
                    value: null,
                    unit: 11
                },
                pressure_pipe_diameter: {
                    value: null,
                    unit: 1
                },
                pressure_pipe_length: {
                    value: null,
                    unit: 1
                },

            },
            rules1: {
                name_cyr: {
                    required: true,
                    message: this.$t('please_enter_data'),
                    trigger: "change",
                },
                address: {
                    required: true,
                    message: this.$t('please_enter_data'),
                    trigger: "change",
                },
                year_of_construction: {
                    required: true,
                    message: this.$t('please_enter_data'),
                    trigger: "change",
                },
                date_of_accept: {
                    required: true,
                    message: this.$t('please_enter_data'),
                    trigger: "change",
                },
                organization_name: {
                    required: true,
                    message: this.$t('please_enter_data'),
                    trigger: "change",
                },
                user_organization_name: {
                    required: true,
                    message: this.$t('please_enter_data'),
                    trigger: "change",
                },
                earthquake_tolerance: {
                    required: true,
                    message: this.$t('please_enter_data'),
                    trigger: "change",
                },
                region: {
                    required: true,
                    message: this.$t('please_enter_data'),
                    trigger: "change",
                },
                district: {
                    required: true,
                    message: this.$t('please_enter_data'),
                    trigger: "change",
                },
                reservoir_class: {
                    required: true,
                    message: this.$t('please_enter_data'),
                    trigger: "change",
                },
                finalconclusion: {
                    required: true,
                    message: this.$t('please_enter_data'),
                    trigger: "change",
                },
                hydraulic_unit_brand: {
                    required: true,
                    message: this.$t('please_enter_data'),
                    trigger: "change",
                },
                hydraulic_unit_count: {
                    required: true,
                    message: this.$t('please_enter_data'),
                    trigger: "change",
                },
                pressure_pipe_count: {
                    required: true,
                    message: this.$t('please_enter_data'),
                    trigger: "change",
                },
                earthquake_tolerance_qmq: {
                    required: true,
                    message: this.$t('please_enter_data'),
                    trigger: "change",
                },
                hydroelectric_power_stations_type: {
                    required: true,
                    message: this.$t('please_enter_data'),
                    trigger: "change",
                },
                buildingseismicity: {
                    required: true,
                    message: this.$t('please_enter_data'),
                    trigger: "change",
                },
                max_water_pressure_height: {
                    value: {
                        required: true,
                        message: this.$t('please_enter_data'),
                        trigger: "change",
                    },
                    unit: {
                        required: true,
                        message: this.$t('please_enter_data'),
                        trigger: "change",
                    },
                },
                hydraulic_unit_water_consumption: {
                    value: {
                        required: true,
                        message: this.$t('please_enter_data'),
                        trigger: "change",
                    },
                    unit: {
                        required: true,
                        message: this.$t('please_enter_data'),
                        trigger: "change",
                    },
                },
                hydraulic_unit_power: {
                    value: {
                        required: true,
                        message: this.$t('please_enter_data'),
                        trigger: "change",
                    },
                    unit: {
                        required: true,
                        message: this.$t('please_enter_data'),
                        trigger: "change",
                    },
                },
                general_water_consumption: {
                    value: {
                        required: true,
                        message: this.$t('please_enter_data'),
                        trigger: "change",
                    },
                    unit: {
                        required: true,
                        message: this.$t('please_enter_data'),
                        trigger: "change",
                    },
                },
                general_power: {
                    value: {
                        required: true,
                        message: this.$t('please_enter_data'),
                        trigger: "change",
                    },
                    unit: {
                        required: true,
                        message: this.$t('please_enter_data'),
                        trigger: "change",
                    },
                },
                pressure_pipe_diameter: {
                    value: {
                        required: true,
                        message: this.$t('please_enter_data'),
                        trigger: "change",
                    },
                    unit: {
                        required: true,
                        message: this.$t('please_enter_data'),
                        trigger: "change",
                    },
                },
                pressure_pipe_length: {
                    value: {
                        required: true,
                        message: this.$t('please_enter_data'),
                        trigger: "change",
                    },
                    unit: {
                        required: true,
                        message: this.$t('please_enter_data'),
                        trigger: "change",
                    },
                },
            },
        }
    },
    computed: {
        dictionary() {
            return this.$store.state.dictionary;
        },
        units() {
            return this.$store.state.units;
        },
        role() {
            return this.$store.state.role;
        },
        page() {
            return this.$route.query.id || '';
        },

    },
    watch: {
        'res1.region': function () {
            this.current_district = [];

            this.loading = true;
            axios.get(`/dictionary/district/?region=${this.res1.region}`)
                .then((response) => {
                    this.current_district = response.data;
                })
                .finally(() => {
                    this.loading = false;
                })
        }
    },
    mounted() { 
        if (this.$route.params.id) {
            this.disabled1 = this.$route.params.edit == '1' ? true : false
            if (this.role === 'admin') {
                this.disabled1 = true;
            }
            this.__GET()
        }

    },
    methods: {
        __GET() {
            if (this.$route.params.id) {
                this.loading = true;
                axios.get(`/reservoir/hydroelectricpowerstations/${this.$route.params.id}`)
                    .then((response) => {
                        response.data.year_of_construction = String(response.data.year_of_construction)
                        this.res1 = response.data;
                    })
                    .finally(() => {
                        this.loading = false;
                    })
            } else {

            }
        },
        save1() {
            this.$refs.res1.validate((valid) => {
                if (valid) {
                    this.loading = true;
                    if (!this.$route.params.id) {
                        axios.post('/reservoir/hydroelectricpowerstations/', this.res1)
                            .then((response) => {
                                axios.post(`/reservoir/hydroelectricpowerstations/${response.data.id}/generate/`)
                                    .then(res => { 
                                        this.pdf = axios.defaults.baseURL.slice(0, -8) + res.data.pdf_link
                                    })

                                this.$swal.fire({
                                    icon: 'success',
                                    title: this.$t('sucsess_save'),
                                    timer: 3000
                                })
                                this.$router.push({
                                    path: '/reservoir/hydroelectricpowerstations'
                                })
                            })
                            .catch(error => {
                                this.$swal.fire({
                                    icon: 'error',
                                    title: this.$t('error_create'),
                                    timer: 3000
                                })
                            })
                            .finally(() => {
                                this.loading = false;
                            })
                    } else {
                        axios.put(`/reservoir/hydroelectricpowerstations/${this.$route.params.id}/`, this.res1)
                            .then((response) => {
                                 axios.post(`/reservoir/hydroelectricpowerstations/${response.data.id}/generate/`)
                                    .then(res => { 
                                        this.pdf = axios.defaults.baseURL.slice(0, -8) + res.data.pdf_link
                                    })

                                this.$swal.fire({
                                    icon: 'success',
                                    title: this.$t('data_saved'),
                                    timer: 3000
                                })
                                this.$router.push({
                                    path: '/reservoir/hydroelectricpowerstations'
                                })
                            })
                            .catch(error => {
                                this.$swal.fire({
                                    icon: 'error',
                                    title: this.$t('error_save'),
                                    timer: 3000
                                })
                            })
                            .finally(() => {
                                this.loading = false;
                            })
                    }
                }

            })
        },
        regionChanged() {
            this.current_district = [];
            this.spec1.district = [];
            this.getDistrict();
        },
        getDistrict() {
            this.loading = true;
            axios
                .get(`/dictionary/district/?region=${this.res1.region}`)
                .then((response) => {
                    this.current_district = response.data;
                })
                .finally(() => {
                    this.loading = false;
                });
        },
    }
}
</script>

<style lang="scss" scoped>
.indicator {
    font-weight: 500;
    font-size: 18px;
    padding: 15px 0px 5px 0px;
    border-bottom: 2px solid #004787;
}

.el-collapse-item__header {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 37px;
    display: flex;
    align-items: center;
    color: #004787 !important;
    padding: 15px 0;
    border-bottom: none;
}

.build-tab {
    display: flex;
    margin-bottom: 20px;
    flex-wrap: wrap;

    li {
        cursor: pointer;
        width: 400px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-bottom: solid 1px #d4d4d4;
        font-size: 17px;
        line-height: 24px;
        color: #004787;
        position: relative;
        user-select: none;

        &.active {
            border-bottom: solid 3px #004787;
        }
    }
}

.uform .Form {
    .el-form-item__content {
        margin-left: 0 !important;
    }
}

.uform .el-form-item {
    margin-bottom: 0 !important;
}
</style>
